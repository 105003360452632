
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Edit2, Trash } from "react-feather";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

const Products = () => {
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const deleteProduct = async productId => {
        console.log("sdasdas");
        let res = await fetch(`https://abcchecks.azurewebsites.net/api/Products/delete/${productId}`, {
            method: 'POST'
        });
        if (res.ok) {
            await reloadList();
        }
    }

    const editProduct = productId => {
        navigate(`/pages/product-variants?productId=${productId}`);
    }

    useEffect(async () => {
        await reloadList();
    }, [])

    const reloadList = async () => {
        let res = await fetch('https://abcchecks.azurewebsites.net/api/Products', {
            method: 'GET'
        });

        let json = await res.json();
        setProducts(json);
    }

    return (
        <>
            <h5>Products</h5>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Category</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((prod, index) => (
                        <tr key={index}>
                            <td>{prod.name}</td>
                            <td>{prod.description}</td>
                            <td>{prod.category ? prod.category.name : "N/A"}</td>
                            <td className="table-action">
                                <Edit2 className="align-middle me-1" size={18} onClick={() => editProduct(prod.id)} />
                                <Trash className="align-middle" size={18} onClick={() => deleteProduct(prod.id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}

export default Products;