const annotations = [
    {
        label: 'Starting Check Number',
        id: 'startingCheckNumber'
    },
    {
        label: 'Routing number',
        id: 'routingNumber'
    },
    {
        label: 'Starting check number bottom',
        id: 'startingCheckNumberBottom'
    },
    {
        label: 'Account number',
        id: 'accountNumber'
    },
    {
        label: 'Address line 1',
        id: 'addressLine1'
    },
    {
        label: 'Address line 2',
        id: 'addressLine2'
    },
    {
        label: 'Address line 3',
        id: 'addressLine3'
    },
    {
        label: 'Address line 4',
        id: 'addressLine4'
    },
    {
        label: 'Bank name',
        id: 'bankName'
    },
    {
        label: 'Routing fraction number',
        id: 'routingFractionNumber'
    }
];

export default annotations;