import { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Form, Button, Table } from "react-bootstrap";

const Backgrounds = () => {

    const [backgrounds, setBackgrounds] = useState([]);

    useEffect(async () => {

        let response = await fetch('https://abcchecks.azurewebsites.net/api/Backgrounds', {
            method: 'GET'
        });

        if (response.ok) {
            let json = await response.json();
            setBackgrounds(json);
            console.log(backgrounds);
        }
    }, [])

    return (
        <>
            <h5>Backgrounds</h5>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>File path</th>
                    </tr>
                </thead>
                <tbody>
                    {backgrounds.map((background, index) => (
                        <tr key={index}>
                            <td>{background.name}</td>
                            <td>{background.filePath}</td>
                            {/* <td className="table-action">
                                <Edit2 className="align-middle me-1" size={18} onClick={() => editProduct(background.id)} />
                                <Trash className="align-middle" size={18} onClick={() => deleteProduct(background.id)} />
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default Backgrounds;