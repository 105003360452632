import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import { useLocation } from "react-router";

import "./ProductVariant.css"

import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from 'fabric';

import annotations from './annotations';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ProductVariant = props => {
    let form = new FormData();

    const { editor, onReady } = useFabricJSEditor();
    const query = useQuery();
    const [data, setData] = useState(annotations);
    const [fontSizeState, setFontSizeState] = useState(annotations.map(x => ({ id: x.id })));
    const [variantForm, setVariantForm] = useState({
        file: '',
        name: '',
        description: '',
        width: 750,
        height: 350,
        numberOfChecks: 0
    })

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.canvas.setDimensions({ width: variantForm.width, height: variantForm.height });

    }, [editor]);

    let variantId = query.get("variantId");
    let productId;
    if (!variantId) {
        productId = query.get("productId")
    }

    const fileSelected = e => {
        form.delete('file');
        form.append('file', e.target.files[0]);
        fabric.Image.fromURL(URL.createObjectURL(e.target.files[0]), function (img) {
            editor.canvas.setBackgroundImage(img, editor.canvas.renderAll.bind(editor.canvas), {
                scaleX: editor.canvas.width / img.width,
                scaleY: editor.canvas.height / img.height
            });
        });
    }

    const variantFormChanged = e => {
        setVariantForm({
            ...variantForm,
            [e.target.name]: e.target.value
        });

        if (e.target.name == "width") {
            editor.canvas.setDimensions({ width: e.target.value, height: editor.canvas.getHeight() });
        }
        if (e.target.name == "height") {
            editor.canvas.setDimensions({ width: editor.canvas.getWidth(), height: e.target.value });
        }
    }

    const inputFocused = e => {
        let existing = editor.canvas.getObjects().find(x => x.text && x.text == e.target.value);

        if (!existing) {
            editor.addText(e.target.value);
            existing = editor.canvas.getObjects().find(x => x.text && x.text == e.target.value);
        }

        editor.canvas.setActiveObject(existing);
        editor.canvas.renderAll();
    }

    const removeSelectedObject = () => {
        editor.canvas.remove(editor.canvas.getActiveObject());
    }

    const fontSizeChanged = e => {
        setFontSizeState([
            ...fontSizeState.filter(x => x.id != e.target.name),
            { id: e.target.name, fontSize: e.target.value }
        ]);

        let valueToSearch = data.find(x => x.id == e.target.name);
        if (!valueToSearch) {
            return;
        }

        let label = valueToSearch.label;
        let object = editor.canvas.getObjects().find(x => x.text && x.text == label);
        if (object) {
            object.fontSize = e.target.value;
            editor.canvas.renderAll();
        }
    }

    const fontFamilyChanged = e => {
        let objects = editor.canvas.getObjects();
        for (let object of objects) {
            object.fontFamily = e.target.value;
        }

        editor.canvas.renderAll();
    }

    const to = (where, id) => {
        let valueToSearch = data.find(x => x.id == id);
        if (!valueToSearch) {
            return;
        }

        let label = valueToSearch.label;
        let object = editor.canvas.getObjects().find(x => x.text && x.text == label);
        object.textAlign = where;
        editor.canvas.renderAll();
    }

    const saveVariant = async () => {
        //console.log("save variant", variantForm);
        let json = JSON.stringify(editor.canvas.getObjects());
        console.log(json);

        setVariantForm({
            ...variantForm,
            width: editor.canvas.getWidth(),
            height: editor.canvas.getHeight()
        });

        let body = {
            ...variantForm,
            placeholdersJson: json
        }

        for (let prop of Object.keys(body)) {
            form.append(prop, body[prop]);
        }

        form.append('productId', productId);

        let resp = await fetch('https://abcchecks.azurewebsites.net/api/ProductVarient', {
            method: 'POST',
            body: form
        });

        if (resp.ok) {
            //console.log("res");
        }
    }

    return (
        <React.Fragment>
            <Helmet title="Blank Page" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Product Variant</h1>
                <Row>
                    <Col md="3">
                        <Form>
                            <Form.Group className="mb-1">
                                <Form.Label>File</Form.Label>
                                <Form.Control type="file" name="file" onChange={fileSelected} />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" onChange={variantFormChanged} value={variantForm.name} />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" name="description" onChange={variantFormChanged} value={variantForm.description} />
                            </Form.Group>
                            <Form.Group className="mb-1">
                                <Form.Label>Width</Form.Label>
                                <Form.Control type="number" name="width" onChange={variantFormChanged} value={variantForm.width} />
                            </Form.Group>

                            <Form.Group className="mb-1">
                                <Form.Label>Height</Form.Label>
                                <Form.Control type="number" name="height" onChange={variantFormChanged} value={variantForm.height} />
                            </Form.Group>

                            <Form.Group className="mb-1">
                                <Form.Label>Number of checks</Form.Label>
                                <Form.Control type="number" name="numberOfChecks" onChange={variantFormChanged} value={variantForm.numberOfChecks} />
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col md={9} className="d-flex justify-content-center" style={{ backgroundColor: '#BBBBBB' }}>
                        <FabricJSCanvas className="canvas mt-3 mb-3" onReady={onReady} />
                    </Col>
                </Row>
                <br />
                <Row>
                    {data.map((d, index) => (
                        <Col md="3" key={index}>
                            <Form.Group className="mb-1">
                                <Form.Control type="text" onFocus={inputFocused} readOnly value={d.label} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm={3} className="text-sm-right">
                                    Font Size
                                </Form.Label>
                                <Col sm={3}>
                                    <Form.Control type="number" name={d.id} onChange={fontSizeChanged} value={fontSizeState[d.id]?.fontSize} />
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" onClick={() => to('left', d.id)}>L</Button>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" onClick={() => to('center', d.id)}>C</Button>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" onClick={() => to('right', d.id)}>R</Button>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                {/* <Form.Check label="Bold" onChange={() =>  } /> */}
                            </Form.Group>
                        </Col>
                    ))}
                </Row>

                <hr />

                <Row>
                    <Col md="3">
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3} className="text-sm-right">
                                Font Size
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Select onChange={fontFamilyChanged}>
                                    <option>Arial</option>
                                    <option>Verdana</option>
                                    <option>Consolas</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Button variant="danger" onClick={removeSelectedObject}>Remove selected</Button>
                    </Col>
                </Row>
                <hr />
                <Row className="mt-1">
                    <Col md={12}>
                        <Button type="submit" variant="primary" onClick={saveVariant}>Save</Button>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default ProductVariant;