import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from '../../utils/axios';

const CreateCheck = (props) => {

    const { reloadList } = props;

    let formData = new FormData();

    const [state, setState] = useState({
        name: '',
        description: '',
        categoryId: 0
    });

    const [categories, setCategories] = useState([]);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const fileHandler = e => {
        formData.delete("file");
        formData.append("file", e.target.files[0]);
    }

    const thumbnailFileHandler = e => {
        formData.delete("thumbnail");
        formData.append("thumbnail", e.target.files[0]);
    }

    const saveCheck = async () => {

        formData.append('name', state.name);
        formData.append('description', state.description);
        formData.append('categoryId', state.categoryId);

        let response = await fetch('https://abcchecks.azurewebsites.net/api/Products', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            setState({
                name: '',
                description: '',

            })
        }

        formData = new FormData();
    }

    const onCategoryChange = e => {
        setState({
            ...state,
            categoryId: +e.target.value
        });
    }

    useEffect(async () => {
        let res = await fetch('https://abcchecks.azurewebsites.net/api/Category', {
            method: 'GET'
        });

        if (res.ok) {
            let json = await res.json();
            setCategories(json);
            setState({
                ...state,
                categoryId: json[0].id
            })
        }
    }, []);

    return (
        <Form>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" type="text" onChange={handleChange} value={state.name}></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control name="description" type="text" onChange={handleChange} value={state.description}></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>
                    File
                </Form.Label>
                <Form.Control type="file" onChange={fileHandler} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>
                    Thumbnail File
                </Form.Label>
                <Form.Control type="file" onChange={thumbnailFileHandler} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>
                    Category
                </Form.Label>
                <Form.Select onChange={onCategoryChange}>
                    {categories.map((cat, index) => (<option key={index} value={cat.id}>{cat.name}</option>))}
                </Form.Select>
            </Form.Group>

            <Button variant="primary" onClick={saveCheck}>Save</Button>
        </Form >
    );
}

export default CreateCheck;