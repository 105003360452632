
import React, { useState, useEffect } from "react";

import { Edit2, Trash } from "react-feather";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Form, Button } from "react-bootstrap";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ProductVariants = (props) => {

    const params = useQuery();
    const productId = params.get("productId")
    const navigate = useNavigate();
    const [productVariants, setProductVariants] = useState([]);

    const createProductVariant = () => {
        navigate(`/pages/product-variant?productId=${productId}`);
    }

    useEffect(async () => {
        let response = await fetch(`https://abcchecks.azurewebsites.net/api/ProductVarient/${productId}`, {
            method: 'GET'
        });

        if (response.ok) {
            let json = await response.json();
            setProductVariants(json);
        }
    }, [])

    return (
        <React.Fragment>
            <Helmet title="Product variants" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Product Variants</h1>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <Button onClick={createProductVariant}>Create product variant</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productVariants.map((prod, index) => (
                                                    <tr key={index}>
                                                        <td>{prod.name}</td>
                                                        <td>{prod.description}</td>
                                                        <td className="table-action">
                                                            <Edit2 className="align-middle me-1" size={18} onClick={() => { }} />
                                                            <Trash className="align-middle" size={18} onClick={() => { }} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default ProductVariants;