import { Button, Card, Col, Container, ListGroup, Row, Tab, Nav, Form } from "react-bootstrap";
import { fabric } from 'fabric';
import Select from "react-select";
import { useState, useEffect } from 'react';
import { address } from "faker";

const Checks = () => {

    const [checkCategories, setCheckCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    const [step, setStep] = useState(1);
    const [backgrounds, setBackgrounds] = useState([]);
    const [checkVariants, setCheckVariants] = useState([]);

    const [canvas, setCanvas] = useState();
    const [fieldMapping, setFieldMapping] = useState();
    const [textareaValue, setTextAreaValue] = useState();

    const [activeTab, setActiveTab] = useState();

    window.addEventListener('resize', resizeCanvas, false);
    window.addEventListener('load', resizeCanvas, false);

    const [productState, setProductState] = useState({
        categoryId: null,
        variantId: null,
        backgroundId: null,
        quantity: null
    });

    useEffect(async () => {
        let checkCategoriesResponse = await fetch('https://abcchecks.azurewebsites.net/api/Category', { method: 'GET' });
        if (checkCategoriesResponse.ok) {
            let json = await checkCategoriesResponse.json();
            setCheckCategories([...json, { id: 0, name: 'None' }]);
            console.log("cat", checkCategories);
        }

        let productsResponse = await fetch('https://abcchecks.azurewebsites.net/api/Products', { method: 'GET' });
        if (productsResponse.ok) {
            let json = await productsResponse.json();
            setProducts(json);
            setFilteredProducts(json);
        }
    }, [])

    const [accountState, setAccountState] = useState({
        line1: { text: undefined },
        line2: { text: undefined },
        line3: { text: undefined },
        line4: { text: undefined }
    });

    const [addressState, setAddressState] = useState({
        bankName: '',
        bankAddress: '',
        bankAddress2: '',
        nineDigitRoutingNumber: '',
        accountNumber: '',
        confirmAccountNumber: '',
        routingFractionNumber: ''
    });

    const [checkInfoState, setCheckInfoState] = useState({
        checkStartingNumber: undefined
    });

    const selectCheck = async id => {
        let variantResponse = await fetch(`https://abcchecks.azurewebsites.net/api/ProductVarient/${id}`, {
            method: 'GET'
        });

        if (variantResponse.ok) {
            let json = await variantResponse.json();
            setCheckVariants(json);
            setStep(2);
        }
    }

    const selectCheckCategory = (id) => {
        setProductState({
            ...productState,
            categoryId: id
        });

        if (id == 0) {
            setFilteredProducts(products);
            return;
        }

        let productsByCategory = products.filter(x => x.categoryId == id);

        if (productsByCategory.length == 0) {
            setFilteredProducts([]);
        }
        else {
            setFilteredProducts(productsByCategory);
        }
    }

    const selectVariant = async (variantId) => {
        setProductState({
            ...productState,
            variantId: variantId
        });

        let backgroundResponse = await fetch(`https://abcchecks.azurewebsites.net/api/backgrounds`, {
            method: 'GET'
        });

        if (backgroundResponse.ok) {
            let json = await backgroundResponse.json();
            setBackgrounds(json);
            setStep(3);
        }
    }

    const selectBackground = id => {
        setActiveTab('second');
        setProductState({ ...productState, backgroundId: id });
        setStep(4);
        setCanvas(new fabric.Canvas('fabric', {
            selection: false
        }));
    }

    useEffect(() => {
        if (!canvas) {
            return;
        }

        let variant = checkVariants.find(x => x.id == productState.variantId);
        if (variant) {
            console.log(variant);
        }

        let background = backgrounds.find(x => x.id == productState.backgroundId);
        if (background) {
            console.log(background);
        }

        canvas.setDimensions({ width: variant.width, height: variant.height });

        let backgroundImageUrl = `https://abcchecks.azurewebsites.net/uploads/${background.filePath}`;
        let checkTransparentImageUrl = `https://abcchecks.azurewebsites.net/uploads/${variant.imageFileName}`;

        fabric.Image.fromURL(backgroundImageUrl, function (img) {
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        }, {
            crossOrigin: 'anonymous'
        });

        fabric.Image.fromURL(checkTransparentImageUrl, function (img) {
            img.set({
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
            canvas.add(img)
        }, {
            hasControls: false,
            evented: false,
            lockMovementX: true,
            lockMovementY: true,
            crossOrigin: 'anonymous'
        });

        fabric.util.enlivenObjects(JSON.parse(variant.placeholdersJson), objects => {
            let fieldMapping = [];

            objects.forEach(function (o) {
                fieldMapping.push({ field: o.text, annotation: o });

                o.text = '';
                o["lockMovementX"] = true;
                o["lockMovementY"] = true;
                o["lockUniScaling"] = true;
                o["hasControls"] = false;
                o["borderColor"] = "red";

                canvas.add(o);
            });

            setFieldMapping(fieldMapping);
            canvas.renderAll();
        });

        //resizeCanvas();
    }, [canvas])

    // const selectQuantity = (quantity) => {
    //     setProductState({
    //         ...productState,
    //         quantity: quantity
    //     })
    // }

    const canNavigateToSecondStep = () => {
        return productState && productState.categoryId && productState.format && productState.quantity;
    }

    const handleAccountFormChange = (e) => {
        setAccountState({
            ...accountState,
            [e.target.name]: { ...accountState[e.target.name], text: e.target.value }
        });

        let keys = getKeys(e.target.name);
        console.log(keys);

        for (let key of keys) {
            let canvasObject = fieldMapping.find(x => x.field == key);
            if (!canvasObject) {
                continue;
            }

            let annotation = canvasObject.annotation;
            if (annotation) {
                annotation.text = e.target.value;
                canvas.renderAll();
            }
        }

        let body = {
            checkInfo: {
                line1: accountState.line1.text,
                line2: accountState.line2.text,
                line3: accountState.line3.text,
                line4: accountState.line4.text,
                ...addressState,
                ...checkInfoState
            },
            selectedProduct: {
                id: 19,
                name: ''
            },
            selectedBackground: {
                id: productState.backgroundId,
                name: ''
            },
            endFile: canvas.toDataURL({
                format: "jpeg"
            })
        };

        setTextAreaValue(JSON.stringify(body));
    }

    const handleCheckInfoFormChange = (e) => {
        setAddressState({
            ...addressState,
            [e.target.name]: e.target.value
        });

        let keys = getKeys(e.target.name);


        for (let key of keys) {
            let canvasObject = fieldMapping.find(x => x.field == key);
            console.log(canvasObject);
            if (!canvasObject) {
                continue;
            }

            let annotation = canvasObject.annotation;
            if (annotation) {
                annotation.text = e.target.value;
                canvas.renderAll();
            }
        }

        let body = {
            checkInfo: {
                line1: accountState.line1.text,
                line2: accountState.line2.text,
                line3: accountState.line3.text,
                line4: accountState.line4.text,
                ...addressState,
                ...checkInfoState
            },
            selectedProduct: {
                id: 19,
                name: ''
            },
            selectedBackground: {
                id: productState.backgroundId,
                name: ''
            },
            endFile: canvas.toDataURL({
                format: "jpeg"
            })
        };

        setTextAreaValue(JSON.stringify(body));
    }

    function resizeCanvas() {
        if (canvas) {
            const outerCanvasContainer = document.getElementsByClassName('fabric-canvas-wrapper')[0];

            if (!outerCanvasContainer) {
                return;
            }

            let variant = checkVariants.find(x => x.id == productState.variantId);
            if (!variant) {
                return;
            }



            const ratio = canvas.getWidth() / canvas.getHeight();
            const containerWidth = outerCanvasContainer.clientWidth;
            const containerHeight = outerCanvasContainer.clientHeight;

            const scale = containerWidth / canvas.getWidth();
            const zoom = canvas.getZoom() * scale;

            console.log(containerWidth, variant.width);
            if (containerWidth < variant.width) {

                canvas.setDimensions({ width: containerWidth });
                canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            }

            if ((containerWidth / ratio) < variant.height) {
                canvas.setDimensions({ height: containerWidth / ratio });
                canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            }

            // canvas.setDimensions({ width: containerWidth, height: containerWidth / ratio });

        }
    }

    const useImage = e => {
        let howMuch = -90;
        if (e.target.checked) {
            howMuch = 90;
        }

        let objects = fieldMapping.filter(x => x.field && x.field.startsWith("Address")).map(x => x.annotation);
        console.log(objects);
        for (let obj of objects) {
            obj.left += howMuch;
            console.log(obj);
            obj.setCoords();
        }
        canvas.renderAll();
    }

    const handleCheckInfoChanged = e => {
        setCheckInfoState({
            ...checkInfoState,
            [e.target.name]: e.target.value
        });

        let keys = getKeys(e.target.name);
        for (let key of keys) {
            let canvasObject = fieldMapping.find(x => x.field == key);
            if (!canvasObject) {
                continue;
            }

            let annotation = canvasObject.annotation;
            if (annotation) {
                annotation.text = e.target.value;
                canvas.renderAll();
            }
        }

        let body = {
            checkInfo: {
                line1: accountState.line1.text,
                line2: accountState.line2.text,
                line3: accountState.line3.text,
                line4: accountState.line4.text,
                ...addressState,
                ...checkInfoState
            },
            selectedProduct: {
                id: 19,
                name: ''
            },
            selectedBackground: {
                id: productState.backgroundId,
                name: ''
            },
            endFile: canvas.toDataURL({
                format: "jpeg"
            })
        };

        setTextAreaValue(JSON.stringify(body));
    }

    const highlightAnnotation = (e) => {
        let keys = getKeys(e.target.name);

        for (let key of keys) {
            let el = fieldMapping.find(x => x.field == key);
            if (el) {
                let ann = el.annotation;
                canvas.setActiveObject(ann);

                canvas.renderAll();
            }
        }
    }

    // const addToCart = async () => {
    //     console.log("Add to cart");

    //     console.log(body);
    //     let formData = new FormData();
    //     formData.append('product', JSON.stringify(body));
    //     await fetch('https://abccheckprinting.webarysites.com/api/customizer/add_to_cart.php', {
    //         method: 'POST',
    //         body: formData,
    //         redirect: 'follow'
    //     });
    // }

    const getKeys = propName => {
        let propNames = [];
        switch (propName) {
            case 'line_1':
                propNames = ["Address line 1"]
                break;
            case 'line_2':
                propNames = ["Address line 2"]
                break;
            case 'line_3':
                propNames = ["Address line 3"]
                break;
            case 'line_4':
                propNames = ["Address line 4"]
                break;
            case 'check_starting_number':
                propNames = ["Starting Check Number", "Starting check number bottom"]
                break;
            case 'nineDigitRoutingNumber':
                propNames = ["Routing number"];
                break;
            case 'accountNumber':
                propNames = ["Account number"];
                break;
            case 'routingFractionNumber':
                propNames = ["Routing fraction number"];
                break;
            case 'bankName':
                propNames = ["Bank name"]
                break;
            default:
                propNames = []
                break;
        }
        return propNames;
    }

    return (
        <Container fluid className="p-4">
            <Row style={{ height: '520px' }}>
                <Col md="6" className="mx-auto">
                    <div className={"tab default"}>
                        <Tab.Container transition={true} variant={"tabs"} id="left-tabs-example" defaultActiveKey="first" activeKey={activeTab} onSelect={k => setActiveTab(k)}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Product</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    {/* disabled={!canNavigateToSecondStep()} */}
                                    <Nav.Link eventKey="second">Account</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Address</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="forth">Check Info</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <h4 className="tab-title">Product details</h4>

                                    <p>Type of check</p>
                                    <div className="mb-3">
                                        {checkCategories.map((check, index) => <Button key={index} disabled={productState.categoryId == check.id} className="btn-pill me-1 mb-1" onClick={() => selectCheckCategory(check.id)} variant="primary">{check.name}</Button>)}
                                    </div>

                                    {/* <p>Variant of check {step == 2 && "(please select a check)"}</p>
                                    <div className="mb-3">
                                        {checkVariants.map((format, index) => <Button key={index} disabled={productState.variantId == format.id} className="btn-pill me-1 mb-1" onClick={() => selectVariant(format.id)} variant="primary">{format.name}</Button>)}
                                    </div> */}

                                    {/* <p>Select quantity</p>
                                    <div className="mb-3" disabled={productState.checkType != undefined}>
                                        {quantity.map((quantity, index) => <Button key={index} disabled={productState.quantity == quantity.type} className="btn-pill me-1 mb-1" onClick={() => selectQuantity(quantity.type)} variant="primary">{quantity.name}</Button>)}
                                    </div> */}
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label htmlFor="line_1">Line 1</Form.Label>
                                            <Form.Control
                                                onChange={handleAccountFormChange}
                                                value={accountState.line1.text}
                                                onFocus={highlightAnnotation}
                                                type="text"
                                                name="line_1"
                                                id="line_1"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label htmlFor="line_2">Line 2</Form.Label>
                                            <Form.Control
                                                onChange={handleAccountFormChange}
                                                value={accountState.line2.text}
                                                onFocus={highlightAnnotation}
                                                type="text"
                                                name="line_2"
                                                id="line_2"
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label htmlFor="line_3">Line 3</Form.Label>
                                            <Form.Control
                                                onChange={handleAccountFormChange}
                                                value={accountState.line3.text}
                                                onFocus={highlightAnnotation}
                                                type="text"
                                                name="line_3"
                                                id="line_3"
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label htmlFor="line_4">Line 4</Form.Label>
                                            <Form.Control
                                                onChange={handleAccountFormChange}
                                                value={accountState.line4.text}
                                                onFocus={highlightAnnotation}
                                                type="text"
                                                name="line_4"
                                                id="line_4"
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check
                                                type="switch"
                                                onChange={useImage}
                                                id="exampleCustomSwitch"
                                                name="customSwitch"
                                                label="Use Logo?"
                                            />
                                        </Form.Group>
                                    </Form>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Bank Name</Form.Label>
                                            <Form.Control
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.bankName}
                                                type="text"
                                                name="bankName"
                                                id="bank_name"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Bank Address</Form.Label>
                                            <Form.Control
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.bankAddress}
                                                type="text"
                                                name="bankAddress"
                                                id="bank_address"
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Bank's City, State, Zip</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.bankAddress2}
                                                name="bankAddress2"
                                                id="bank_city_state_zip"
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>9 Digit routing Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.nineDigitRoutingNumber}
                                                name="nineDigitRoutingNumber"
                                                id="9_digit_routing_number"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Account Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.accountNumber}
                                                name="accountNumber"
                                                id="account_number"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Confirm Account Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.confirmAccountNumber}
                                                name="confirmAccountNumber"
                                                id="confirm_account_number"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Routing Fraction Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleCheckInfoFormChange}
                                                onFocus={highlightAnnotation}
                                                value={addressState.routingFractionNumber}
                                                name="routingFractionNumber"
                                                id="routing_fraction_number"
                                            />
                                        </Form.Group>
                                    </Form>
                                </Tab.Pane>
                                <Tab.Pane eventKey="forth">
                                    <Form.Group>
                                        <Form.Label>Check Starting number</Form.Label>
                                        <Form.Control
                                            value={checkInfoState.checkStartingNumber}
                                            onChange={handleCheckInfoChanged}
                                            onFocus={highlightAnnotation}
                                            type="text"
                                            name="check_starting_number"
                                            id="check_starting_number"
                                        />
                                    </Form.Group>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
                {
                    (step == 1) && (
                        <Col md="6" className="mx-auto text-center pt-3 pb-3 align-items-center">
                            <h3>Available checks</h3>
                            {filteredProducts.map((prod, index) =>
                            (
                                <Card key={index} onClick={() => selectCheck(prod.id)}>
                                    <Card.Body>
                                        <Row>
                                            <Col md={3}>
                                                <img src={`https://abcchecks.azurewebsites.net/uploads/${prod.imageFileName}`} style={{ width: '150px', height: '50px' }} />
                                            </Col>
                                            <Col md={9} className="mx-auto text-center pt-3 pb-3 align-items-center">
                                                <div className="align-self-center">
                                                    <h4>{prod.name}</h4>
                                                    <h6>{prod.description}</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            ))}
                            {filteredProducts.length == 0 ? (<p>No available checks</p>) : null}
                        </Col>
                    )
                }
                {
                    (step == 2) && (
                        <Col md="6" className="mx-auto text-center pt-3 pb-3 align-items-center">
                            <h3>Select variant</h3>
                            {checkVariants.map((variant, index) =>
                            (
                                <Card key={index} onClick={() => selectVariant(variant.id)}>
                                    <Row className="mt-3 mb-3">
                                        <Col md={3}>
                                            <img src={`https://abcchecks.azurewebsites.net/uploads/${variant.imageFileName}`} style={{ width: '150px', height: '50px' }} />
                                        </Col>
                                        <Col md={9} className="mx-auto text-center pt-3 pb-3 align-items-center">
                                            <div className="align-self-center">
                                                <h4>{variant.name}</h4>
                                                <h6>{variant.description}</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>
                    )
                }
                {
                    (step == 3) && (
                        <Col md="6" className="mx-auto text-center pt-3 pb-3 align-items-center">
                            <h3>Select Background</h3>
                            {backgrounds.map((background, index) =>
                            (
                                <Card key={index} onClick={() => selectBackground(background.id)}>
                                    <Row className="mt-3 mb-3">
                                        <Col md={3}>
                                            <img src={`https://abcchecks.azurewebsites.net/uploads/${background.filePath}`} style={{ width: '150px', height: '50px' }} />
                                        </Col>
                                        <Col md={9} className="mx-auto text-center pt-3 pb-3 align-items-center">
                                            <div className="align-self-center">
                                                <h4>{background.name}</h4>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>
                    )
                }


                <Col md="6" className="mx-auto text-center  align-items-center" style={{ display: (step == 4) ? 'block' : 'none' }}>
                    <div className="fabric-canvas-wrapper justify-content-center d-flex m-3 pt-3 pb-3" style={{ background: '#EEEEEE' }}>
                        <canvas id="fabric"></canvas>
                    </div>
                    <form action="https://abccheckprinting.webarysites.com/api/customizer/add_to_cart.php" method="post" target="_blank">
                        <textarea id="product" name="product" value={textareaValue} style={{ display: 'none' }}></textarea>
                        <input type="submit" value="Add To Cart" />
                    </form>
                </Col>

            </Row>



        </Container>
    )
}
export default Checks;