import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Form } from "react-bootstrap";

import Products from "../checks/Products";
import Backgrounds from "../checks/Backgrounds";
import CreateCheck from "../../components/check/CreateCheck";
import CreateBackground from "../../components/check/CreateBackground";

const Blank = () => (
  <React.Fragment>
    <Helmet title="Checks Admin" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Checks admin</h1>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col md="6">
                  <Products />
                </Col>
                <Col md="6">
                  <Backgrounds />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <h4>Create Product</h4>
          <CreateCheck />
        </Col>
        <Col md="6">
          <h4>Create Background</h4>
          <CreateBackground />
        </Col>
      </Row>

    </Container>
  </React.Fragment>
);

export default Blank;
